<template>
    <div class="bg">
        <div class="center">
            <div class="rightdiv">
                <div class="right-top">
                    <!-- <img src="/images/sign/tfhw_logo.png" class="logo-img">
                    <div class="logo-text">
                        天府好物
                    </div> -->
                </div>
                <!-- <a-image :width="150" src="https://t2.oss-chengdu1.yunzhicloud.com/town/wiki/Tianfu-Haowu-app-QRcode.jpg"
                    :preview="false" /> -->
            </div>
            <div class="wrap">
                <div class="title">四川文创集市申报</div>

                <div class="form">
                    <a-form :model="form" :labelCol="{ style: { width: '145px' } }" :wrapperCol="{ span: 20 }"
                        :rules="rules" ref="ruleForm">
                        <a-form-item label="集市名称" name="opusName">
                            <a-input v-model:value="form.opusName" placeholder="请输入" :maxlength="30" :showCount="true" />
                        </a-form-item>
                        <!-- <a-form-item label="作品名称" name="opusName">
                            <a-input v-model:value="form.opusName" placeholder="请输入" :maxlength="30" :showCount="true" />
                        </a-form-item> -->
                        <!-- <a-form-item label="参赛单位/个人名称" name="company">
                            <a-input v-model:value="form.company" placeholder="请输入" :maxlength="30" :showCount="true" />
                        </a-form-item> -->
                        <a-form-item label="邀请码" name="company">
                            <a-input v-model:value="form.company" placeholder="请输入" :maxlength="30" :showCount="true" />
                        </a-form-item>
                        <a-form-item label="联系人" name="userName">
                            <a-input v-model:value="form.userName" placeholder="请输入" :maxlength="5" :showCount="true" />
                        </a-form-item>
                        <a-form-item label="电话" name="mobile">
                            <a-input v-model:value="form.mobile" placeholder="请输入" :maxlength="11" :showCount="true" />
                        </a-form-item>
                        <!-- <a-form-item label="参赛组别" name="groupName">
                            <a-select v-model:value="form.groupName" size="1" style="width: 200px" :options="options"
                                placeholder="请选择"></a-select>
                        </a-form-item>
                        <a-form-item label="作品类型" name="opusType">
                            <a-select v-model:value="form.opusType" size="1" style="width: 200px" :options="productTypes"
                                placeholder="请选择"></a-select>
                        </a-form-item> -->
                        <!-- <a-form-item label="申报单位" name="company">
                            <a-input v-model:value="form.company" placeholder="请输入" :maxlength="30" :showCount="true" />
                        </a-form-item> -->
                        <!-- <a-form-item label="邀请码" name="invitationCode">
                            <a-input v-model:value="form.invitationCode" placeholder="请输入" :maxlength="30"
                                :showCount="true" />
                        </a-form-item> -->
                        <a-form-item label="申报资料下载">
                            <a-button @click="downsignup">点击下载申报资料</a-button>
                        </a-form-item>
                        <a-form-item label="资料上传" name="fileList">
                            <div>
                                <a-upload :file-list="fileList" @remove="handleRemove" list-type="picture-card"
                                    :before-upload="beforeUpload" accept=".rar,.zip" :maxCount="1" :auto-upload="false">
                                    <div class="ant-upload-text">点击上传资料.rar或.zip格式</div>
                                </a-upload>
                            </div>
                        </a-form-item>
                        <a-form-item label="上传须知">
                            <div class="desc">
                                <div>
                                    1.请将视频文件及申报材料放在一个以集市名称命名的文件夹中压缩上传。
                                </div>
                                <div>
                                    2.视频文件要求画质不低于1920*1080，时长3-5分钟。
                                </div>
                                <div>
                                    3.申报表中“基本情况”、“地域特色”、“文化创意”等填写可另附word。
                                </div>
                                <div>
                                    4.上传的整体压缩包文件不得大于500M
                                </div>
                           
                            </div>
                        </a-form-item>


                    </a-form>
                    <div style="display: flex;margin-bottom: 0.5rem;">
                        <a-button>取消</a-button>
                        <div style="width: 0.2rem;" />
                        <a-button type="primary" @click="onSubmit" :loading="loading">提交</a-button>
                    </div>
                </div>


            </div>
            <div class="rightdiv">
                <!-- <a-image :width="150" src="https://t2.oss-chengdu1.yunzhicloud.com/town/wiki/Tianfu-Haowu-app-QRcode.jpg"
                    :preview="false" /> -->
            </div>

            <a-modal v-model:visible="visible" @ok="handleOk" :closable="false" :footer="null" :maskClosable="false">
                <div class="moelview">

                    <p class="modeltitle">{{ loading ? "正在上传中，请不要关闭页面" : uploadFail ? "上传失败" : "上传成功" }}</p>
                    <div v-if="isShowProgress">
                        <Lottie :options="lottieOptions" :width="300" :height="140" v-on:animCreated="handleAnimation" />
                    </div>
                    <a-button type="primary" @click="uploadAgain" size="big" v-show="!loading" style="margin-top: 10px;">{{ uploadFail ? "重新上传" :
                        "再次上传" }}</a-button>
                </div>
            </a-modal>
            <a-modal v-model:visible="open" title="第四届天府文创大赛暨红色文创大赛参赛作品版权承诺书" @ok="agree" :closable="false"
                :maskClosable="false" okText="我同意" :cancel-button-props="{ hidden: true }">

                <p class="content">
                    本人(团队、单位)已详细阅读《第四届天府文创大赛暨红色文创大赛征集公

                    告》，无任何异议。本人在充分理解并自愿接受大赛通知和规则的前提下，特向

                    主办方做出如下承诺，本人(团队、单位)知悉并承诺：
                </p>

                <p class="content">1.本人(团队、单位)所有提交至主办方的应征作品应为原创作品，不得照搬、

                    抄袭、套用、嫁接他人作品和创意，不得侵犯第三方专利、著作权、商标权及其他

                    知识产权权益。如应征作品涉及侵权纠纷，由此导致的一切不良后果和责任均由

                    本人(团队、机构)承担与主办方无关。给主办方造成损失或不良影响的，本人(团

                    队、单位)承担相关责任。
                </p>
                <p class="content">

                    2.本人(团队、单位)所有提交至主办方的作品的著作权受中国相关法律保护，

                    符合国家相关法律法规要求且保证完整性，完全原创，无剽窃行为。如作品涉及

                    抄袭、借用等侵权行为，一经发现将取消作品应征资格，所有因此而引发的法律

                    责任应由本人(团队、单位)承担，与主办方无关。
                </p>
                <p class="content">

                    3.本人(团队、单位)同意根据大赛组委会宣传推广等非商业性用途的需要，

                    参加主办单位的展示、宣传推广、汇编及评选等活动。
                </p>
                <p class="content">

                    4.本人(团队、单位)在提交作品时，即表明认同本征集公告的全部内容，承

                    诺如实填写参赛报名表相关内容，并对填写内容负法律责任。
                </p>

            </a-modal>
        </div>

    </div>
</template>

<script>
import api from '@/api'
import { message } from 'ant-design-vue';
import Lottie from 'vue-lottie';
import lottieJson from "@/assets/progress.json";

export default {
    components: {
        Lottie
    },
    data() {
        return {
            isShowProgress:true,
            open: false,
            visible: false,
            loading: false,//正在上传
            form: {
                userName: '',
                mobile: '',
                company: '',
                opusName: '',
                resourceName: '',
                resourceUrl: '',
                fileList: null,
                groupName: '熊猫文创',
                opusType:'实物类'

            },
            uploadFail: false,//上传失败
            imageUrl: '',
            fileList: [],
            options: [
                { 'label': '熊猫文创', 'value': '熊猫文创' },
                { 'label': '古蜀文创', 'value': '古蜀文创' },
                { 'label': '天府文创', 'value': '天府文创' },
                { 'label': '安逸文创', 'value': '安逸文创' },
                { 'label': '红色文创', 'value': '红色文创' }
            ],
            productTypes: [
                { 'label': '实物类', 'value': '实物类' },
                { 'label': '设计类', 'value': '设计类' },
            ],

            rules: {
                opusName: [{ required: true, message: '请输入集市名称', trigger: 'change' }],
                mobile: [{ required: true, message: '请输入电话', trigger: 'change' }],
                company: [{ required: true, message: '请输入邀请码', trigger: 'change' }],
                userName: [{ required: true, message: '请输入联系人', trigger: 'change' }],
                groupName: [{ required: true, message: '请选择参赛组别', trigger: 'change' }],
                opusType: [{ required: true, message: '请选择作品类型', trigger: 'change' }],
                // invitationCode: [{ required: true, message: '请输入邀请码', trigger: 'change' }],
                fileList: [{ required: true, message: '请选择', trigger: 'change' }],
            },
            // Lottie 配置选项
            lottieOptions: {
                animationData: lottieJson, // Lottie JSON 文件路径
                loop: false, // 是否循环播放
                autoplay: false, // 是否自动播放
            },
            currentFrame: 0,

        }
    },
    watch: {
        "form.mobile"(newVal, oldVal) {
            this.form.mobile = newVal.replace(/\D/g, '');
        }
    },
    methods: {
        handleAnimation: function (anim) {

            this.anim = anim;
            this.anim.playSegments([this.currentFrame, 8], true)
        },
        updateAnimation(num) {
            this.anim.playSegments([this.currentFrame, num], false)
            this.currentFrame = num
        },
        onSubmit() {

            this.$refs.ruleForm.validate().then(() => {
                this.updateFile(this.fileList[0]);
            }).catch(error => {
            })


        },


        downsignup() {
            window.location.href = "https://t2.oss-chengdu1.yunzhicloud.com/town/wiki/%E6%96%87%E5%88%9B%E9%99%A2%E3%80%8A%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%81%9A%E5%A5%BD%E7%AC%AC%E4%B8%89%E6%89%B9%E2%80%9C%E5%9B%9B%E5%B7%9D%E6%96%87%E5%88%9B%E9%9B%86%E5%B8%82%E2%80%9D%E7%9A%84%E6%8E%A8%E8%8D%90%E5%B7%A5%E4%BD%9C%E7%9A%84%E5%87%BD%E3%80%8B.pdf"
        },
        beforeUpload(file) {

            var fileSizeOK = file.size / 1024 / 1024 <= 500;
            if (!fileSizeOK) {
                message.error('文件不能超过500M');
                return;
            }
            // if (!file.type.toUpperCase().endsWith("ZIP") && !file.type.toUpperCase().endsWith("RAR")) {
            //     message.error('请选择.rar或.zip格式的文件');
            //     return;
            // }
            console.log(file);
            this.file = file;
            this.fileList = [file]
            this.form.fileList = [file]
            return false;
        },
        handleChange() {

        },
        handleRemove() {

            this.fileList = []
            this.form.fileList = null
        },
        async updateFile(file) {
            this.loading = true;
            this.visible = true;

            let formData = new FormData();

            formData.append('file', file, file.name)
            formData.append('type', "RAR")
            this.form.resourceName = file.name;

            this.isShowProgress = true;

            const res = await api.uploadFile(formData, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log('上传进度：', progress);
                this.updateAnimation(8 + progress);
            })
            this.updateAnimation(120);
            this.isShowProgress = false;
            this.currentFrame = 0
            if (res && res.code == 200) {
              
                this.uploadData(res.data.url);
            } else {
                this.loading = false;
                this.uploadFail = true;
                this.visible = true;

            }
        },
        uploadAgain() {
            if (this.uploadFail) { //上传失败
                this.visible = false;
                // this.updateFile(this.fileList[0]);
            } else {//上传成功
                this.fileList = []
                this.form.fileList = null
                this.visible = false;
                this.$refs.ruleForm.resetFields();
            }

        },
        agree() {
            this.open = false;
        },

        async uploadData(url) {
            this.form.resourceUrl = url;
            const res = await api.tianfuSignUp(this.form);
            console.log(res);
            this.loading = false;
            if (res.code == 200) {
                this.fileList = []
                this.uploadFail = false;
                this.form.fileList = null
                this.visible = true;
                this.$refs.ruleForm.resetFields();
            } else {

                this.uploadFail = true;
                this.visible = true;
            }


        }
    }
}
</script>

<style lang="scss" scoped>
.bg {
    // background: url("https://t2.oss-chengdu1.yunzhicloud.com/town/wiki/WechatIMG300.png");
    background: url('/images/sign/bg.jpg');
    min-height: calc(100vh - 1.06rem);
    background-size: 100% 100%;
    background-repeat: no-repeat; //将图片样式不重复

    // position: fixed;
    /* 充满全屏 */
    height: 100%; //设置div高度
    width: 100%; //设置div宽度

    display: flex;
    justify-content: center;
    align-items: center;
}

.wrap {
    min-height: calc(100vh - 1.06rem);
    background: #ffffff;
    width: 50%;
    display: flex;
    min-width: 10rem;
    align-items: center;
    flex-direction: column;

    .title {
        margin-top: 0.5rem;
        font-size: 30px;
        font-weight: bold;
    }

    .form {
        // background: rebeccapurple;
        margin-top: 0.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .leftText {}

    .desc {
        width: 6rem;
        padding-top: 0.05rem;
    }


}

.avatar-uploader {}

.moelview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modeltitle {
        // margin-bottom: 0.5rem;
        margin-top: 0.2rem;
        font-size: 20px;
    }
}

/deep/.ant-upload {
    width: 100%;
    height: 58px;

}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.center {
    display: flex;
    justify-items: center;
    align-items: center;

}

.logo-img {
    width: 80px;
    height: 80px;
    display: flex;

}

.right-top {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;

    .logo-text {
        margin-left: 8px;
        color: #ffffff;
        font-size: 25px;
        font-weight: bold;
    }
}

.rightdiv {

    width: 378px;
    height: 489px;

    display: flex;
    justify-content: center;
    align-items: center;


}

.content {
    color: gray;
}
</style>